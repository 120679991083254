module.exports = {
	message: {
		bottom: {
			language: "EN",
			about: "关于我们",
			agreement1: "用户协议",
			agreement2: "隐私协议",
			secure: "交友安全",
			help: "帮助中心",
			contact: "联系我们",
		},
		navigation: {
			language: "EN",
			home: "首页",
			post: "帖子",
			gift: "礼物",
			upgrades: "升级",
			topup: "充值",
			homepage: "我的主页",
			set: "个人设置",
			invite: "邀请好友",
			logout: "退出登录",
			nologged: "未登录",
			search: "搜索",
			input: '请输入用户名'
		},
		login: {
			welcome: "欢迎使用",
			introduce: "介绍",
			welcomelogin: "欢迎登录",
			nohave: "还没有账号？",
			register: "去注册",
			username: "请输入邮箱或用户名",
			password: "请输入密码",
			remember: "记住密码",
			forgot: "忘记密码",
			login: "登录",
		},
		signin: {
			fillreset: "填写注册信息",
			upload: "上传头像",
			information: "填写基本信息",
			email: "邮箱",
			email2: "请输入常用邮箱",
			nickname: "昵称",
			name: '请输入昵称(限2-8个字)',
			setpassword: "设置密码",
			password: "请输入密码(6-15个字符，区分大小写)",
			confirmpassword: "确认密码",
			nextpassword: "请再次输入密码",
			pwd: "密码不一致",
			back: "返回",
			laststep: "上一步",
			next: "下一步",
			photo: "请上传头像",
			sex: "性别",
			Boy: "男生",
			schoolgirl: "女生",
			localarea: "所在地区",
			nation: "国家",
			province: "省",
			city: "市",
			oneself: "介绍自己",
			yourself: "请输入内容介绍自己",
		},
		reset: {
			reset: "重置密码",
			email: "邮箱",
			email2: "请输入常用邮箱",
			setpassword: "设置密码",
			password: "请输入密码(6-15个字符，区分大小写)",
			confirmpassword: "确认密码",
			nextpassword: "请再次输入密码",
			OK: "确定",
		},
		pop: {
			service: "客服",
			send: "发送",
			inputtext: "输入文字",
			online: "在线",
			Offline: "离线",
			profile: "个人简介",
			cleartext: "确定要清空聊天记录吗？",
			received: "收到的赞",
			liked: "我点的赞",
			lv: "等级",
			EXP: "经验值",
			share: "分享",
			forward: "来自：转发",
			website: "来自：网站",
			picture: "图片",
			photo: "相册",
			dynamics: "TA的动态",
			TRANSLATORS: "翻译",
			forward2: "确定转发该帖子吗？",
			delete: "确定删除该帖子吗？",
			delete2: "确定删除该图片吗？",
			send2: "发布您的评论",
			from: "来自",
			tag: "标签",
			personal: "个人资料",
			emote: "表情",
			video: "视频",
			TA: "TA",
			untranslate: "不翻译",
			text1: "不良信息是指含有色情、暴力、广告或其他骚扰你正常工作生活的内容",
			text2: "您要举报的信息：",
			text3: "填写您要举报的原因：",
			text4: "请放心填写，我们会保护您的隐私",
			fans: "我的粉丝",
		},
		btn: {
			chat: "聊天",
			clear: "清空",
			verify: "确认",
			cancel: "取消",
			clearCon: "清空内容",
			prompt: "不再提示",
			uncheck: "取关",
			close: "关闭",
			report: "举报",
			publish: "发布",
			add: "添加",
			delete: "删除",
			upload: '上传',
			submit: '提交'
		},
		home: {
			vermicelli: "粉丝",
			dynamic: "动态",
			collect: "收藏",
			message: "私聊",
			visitor: "来访记录",
			service: "联系客服",
			system: "系统消息",
			follow: "关注",
			like: "点赞",
			me: "@我",
			sendPost: "发帖子",
			ordinary: "普通会员 ",
			diamond: "钻石会员",
			permanent: "永久会员",
		},
		index: {
			all: "全部",
			recommended: "推荐",
			send: "送TA",
			aite: "@TA",
			direct: "私聊",
		},
		post: {
			emote: "表情",
			video: "视频",
			picture: "图片",
			ta: "TA",
			untranslate: "不翻译",
			yu:'语音'
		},
		gift: {
			total: "全部礼物",
			mygift: "我的礼物",
			all: "全部",
			virtual: "虚拟礼物",
			accessory: "首饰",
			doll: "玩偶",
			flower: "鲜花",
			watch: "手表",
			details: "详情",
			inventory: "库存",
			specification: "规格",
			buy: "购买",
			text: "请输入祝福语",
			quantity: "数量",
			anonymous: "匿名赠送",
			open: "公开赠送",
			private: "私下赠送",
			desc: "简介"
		},
		upgrades: {
			membership: "升级会员",
			section: "部分功能",
			full: "全部功能",
			text: "您已经是永久会员了！",
			successfully: "升级成功~",
			text2: "金币不足请先充值~",
			text3: "确定升级会员吗？",
			text4: "去充值",
			permanent: "永久",
		},
		topup: {
			coin: "充值金币",
			amount: "金额",
			other: "其他金额",
			information: "充值信息",
			text: "请输入其他金额",
			text2: "充值记录/赠送记录",
			serial: "流水号",
			payee: "收款用户",
			recharge: "充值方式",
			time: " 时间",
			status: "状态",
		},
		setting: {
			information: "基本信息",
			translation: "购买翻译包",
			privacy: "隐私设置",
			notification: "通知",
			blacklist: "用户黑名单",
			security: "账号安全",
			invitation: "邮箱邀请",
			invitation2: "链接邀请",
			avatar: "头像",
			loginemail: "登录邮箱",
			areaname: "地区名称",
			addtag: "添加标签",
			unmodifiable: "不可修改",
			package: "翻译包",
			remaining: "剩余",
			character: "字符",
			text1: "选择翻译包套餐（字符） ",
			text2: "谁可以评论我的帖子",
			text3: "谁可以点赞我的帖子",
			text4: "谁可以收藏我的帖子",
			text5: "所有人（不包括黑名单用户）",
			text6: "我关注的人",
			text7: "接收系统通知邮件：当收到@，评论，私信提醒时，设置是否接收系统发出的通知邮件以及接收邮件的周期 ",
			alluding: "提到我的",
			review: "评论",
			letter: "私信",
			reminder: "邮件提醒",
			receive: "接收系统邮件",
			receive2: "不接收系统邮件",
			never: "从不接收",
			daily: "每天接收",
			weekly: "每周接收",
			monthly: "每月接收",
			shift: "移出",
			text8: "被加入黑名单的用户将无法关注你，评论你和查看你的个人空间。如果你已经关注TA，也会自动解除关系。",
			addblacklist: "添加黑名单",
			changepassword: "修改密码",
			bound: "已绑定邮箱",
			original: "原始密码",
			newpwd: "新密码",
			text9: "你可以通过邮箱邀请好友，好友成功注册后将获50点经验",
			confirm: "确认邀请",
			invitation3: "邀请列表",
			text10: "请输入邮箱"
		},
		othor: {
			online: "在线",
			Offline: "离线",
			gift: '赠送',
			giftHer: '赠送给',
			immediately: '立即赠送',
			Allread: '全部已读',
			unread: '未读',
			read: '已读',
			text1: '有什么新鲜事告诉大家~',
			input: '请输入',
			trend: '动态详情',
			system: '消息',
			myname: '我的',
			text: '我的金币',
			btnall: '常用功能',
			save: '保存',
			mytrends: '我的动态',
			mysave: '我的收藏',
			mylove: '我的关注',
			member: '会员',
			record: '充值记录',
			Purchase: '购买记录',
			coin: '赠送金币',
			Buynow: '立即购买',
			Invitefriends: '邀请好友',
			Copylink: '复制链接',
			completed: '已完成',
			Blacklist: '黑名单',
			text2: '请选择金额',
			text3: '请完善充值信息',
			text4: '待审核',
			text5: '驳回',
			Liked: '点赞了',
			published: '发布的',
			my: '了我',
			my2: '我',
			text6: '复制链接',
			text7: '会员专享等你来拿',
			text8: '礼物商城',
			text9: '快来选取礼物送好友吧',
			text10: '接收者',
			mount: '总',
			type: '类型',
			money: '充值',
			gomoney: '立即充值',
			number: '其他数量',
			realname: '真实姓名',
			inputName: '请输入真实姓名',
			realnum: '真实手机号',
			inputNum: '请输入真实手机号',
			realEmial: '真实邮箱',
			inputEmial: '请输入真实邮箱',
			butText: '购买信息',
			login1: '同一个世界相遇',
			and: '与',
			you: '你',
			sigin:'立即注册',
			read2:'我已阅读并同意',
			login2:'从世界的另一头',
			sigintit:'注册',
			login3:'相识',
			login4:'在此',
			login5:'我有账号',
			loginbtn:'立即登录',
			end:'完成',
			update:'确认修改',
			memberdesc:'您当前是',
			userinfo:'个人信息',
			userDesc:'请输入个人简介~',
			chatTit:'聊天中',
			chatTitlist:'聊天记录',
			tishi:'请输入有效内容',
			sendText:'赠送记录',
			sendper:'请输入赠送人',
			buyText:'可在购买记录中查看',
			buySucc:'购买成功',
			select:'选择',
			selected:'已选',
			page:'张',
			agreeBtn:'请勾选协议',
			senduser:'赠送用户',
			code:'请输入验证码',
			getCode:'验证码',
			sosoText:'发布成功 请等待审核',
			succTrends:'转发成功',
			total :'共',
			article:'条',
			getmore:'查看更多',
			usdt:'已提交 请等待审核',
			upload:'上传支付凭证',
			address:'地址',
			chinaText:'禁止输入中文'
		},
		goods:{
			succTit:'是否支付成功？',
			succbtn:'支付成功',
			errbtn:'支付失败',
		}
	}
};