<!-- 页面左边 -->
<template>
  <div>
    <div class="userBox">
       <div class="userHead">
            <div class="headPic">
                <div style="width: 100px;height: 100px;border-radius: 50px;overflow: hidden;">
                  <img :src="$imgurl + userInfo.avatar" alt="" style="width: 100px;height: 100px;border-radius: 50px;overflow: hidden;object-fit: cover;">
                </div>
                <div class="lable" :style="{'background':userInfo.level_color}">
                    <img style="width: 25px;height: 25px;margin-right: 5px;" :src="$imgurl + userInfo.level_image" alt="">
                    <p>{{userInfo.level_name}}</p>
                </div>
            </div>
            <div class="user">
                <p>{{userInfo.nickname}}</p>
                <div class="count">
                    <img style="width: 27px;height: 27px;margin-right: 8px;" src="../src/assets/icon/11.png" alt="">
                    <p class="color">{{userInfo.money}}</p>
                </div>
            </div>
       </div>
       <div class="userCount">
            <div class="countList cursor" @click="showPop('vermicelliPop',0)">
                <p>{{countInfo.user_fans_count}}</p>
                <p>{{ i18n.home.vermicelli }}</p>
            </div>
            <div class="countList cursor" @click="$method.skip('/home/dynamicState')">
                <p>{{countInfo.user_trends_count}}</p>
                <p>{{ i18n.home.dynamic }}</p> 
            </div> 
            <div class="countList cursor" @click="$method.skip('/home/love')">
                <p>{{ countInfo.user_collect_count }}</p>
                <p>{{ i18n.home.collect }}</p>
            </div>
       </div>
       <div class="userSet">
            <div class="setList" @click="showPop('directPop',1)">
                <img v-if="selList == 1" src="../src/assets/icon/64.png" alt="">
                <img v-else src="../src/assets/icon/12.png" alt="">
                <p :class="selList == 1?'color':''">{{ i18n.home.message }}</p>
                <p v-if="countInfo.all_user_message_number > 0" class="messageNum shadowbgcolor">{{countInfo.all_user_message_number}}</p>
            </div>
            <div class="setList" @click="showPop('recordsPop',2)">
                <img v-if="selList == 2" src="../src/assets/icon/65.png" alt="">
                <img v-else src="../src/assets/icon/13.png" alt="">
                <p :class="selList == 2?'color':''">{{ i18n.home.visitor }}</p>
            </div>
            <div class="setList" v-if="serviceData.id != userInfo.user_id" @click="showPop('openService',3)">
                <img v-if="selList == 3" src="../src/assets/icon/66.png" alt="">
                <img v-else src="../src/assets/icon/14.png" alt="">
                <p :class="selList == 3?'color':''">{{ i18n.home.service }}</p>
                <p v-if="countInfo.all_customer_message_number > 0" class="messageNum shadowbgcolor">{{countInfo.all_customer_message_number}}</p>
            </div>
            <div class="setList" @click="showPop('systemPop',4)">
                <img v-if="selList == 4" src="../src/assets/icon/67.png" alt="">
                <img v-else src="../src/assets/icon/15.png" alt="">
                <p :class="selList == 4?'color':''">{{ i18n.home.system }}</p>
                <p v-if="messageNum > 0" class="messageNum shadowbgcolor">{{sysNum}}</p>
                <p v-if="countInfo.all_system_message_number > 0" class="messageNum shadowbgcolor">{{countInfo.all_system_message_number}}</p>
            </div>
       </div>
       <div class="bottomBox">
          <div class="bottom"></div>
          <div class="mountBox">
              <div class="mount">
                  <div class="countList" @click="showPop('lovePop',0)">
                      <p>{{countInfo.user_follow_count}}</p>
                      <p>{{ i18n.home.follow }}</p>
                  </div>
                  <div class="countList" @click="showPop('givelikePop',0)">
                      <p>{{countInfo.user_give_count}}</p>
                      <p>{{ i18n.home.like }}</p>
                  </div>
                  <div class="countList" @click="showPop('tipsPop',0)">
                      <p>{{countInfo.user_refer_count}}</p>
                      <p>{{ i18n.home.me }}</p>
                  </div>
              </div>
              <div class="postForm shadowbgcolor cursor" @click="$method.skip('/post')">{{ i18n.home.sendPost }}</div>
          </div>
       </div>
    </div>
    <!-- 私聊 -->
    <!--  -->
    <el-dialog class="direct" width="700px" :show-close="false" :visible.sync="directPop">
      <div class="directBox messageBox">
        <div class="popTit">
          <p>{{i18n.home.message}}</p>
          <div class="btnBox cursor">
            <img @click="openMessage" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="selNow">
          <el-radio-group v-model="radio" @input="sexChange">
              <el-radio :label="1">{{i18n.pop.online}}</el-radio>
              <el-radio :label="2">{{i18n.pop.Offline}}</el-radio>
          </el-radio-group>
        </div>
        <div class="listContent" @scroll="handleScrollUserlist" ref="chatContent">
          <user-list @directUser="directUser" :type="1" :list="userList"></user-list>
        </div>
        <div class="popBottom"></div>
      </div>  
    </el-dialog>
    <!-- 来访记录 -->
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="recordsPop">
      <div class="directBox recordsBox">
        <div class="popTit">
          <p>{{i18n.home.visitor}}</p>
          <div class="btnBox cursor">
            <img @click="recordsPop = false" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="listContent" @scroll="handleScrollrecords" ref="chatContent">
          <user-list @directUser="directUser" :list="comeVisitList" :type="4"></user-list>
        </div>
        <div class="popBottom cursor" @click="recordsClear">{{i18n.btn.clearCon}}</div>
      </div>  
    </el-dialog>
    <!-- 关注 -->
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="lovePop">
      <div class="directBox loveBox">
        <div class="popTit">
          <p>{{i18n.home.follow}}</p>
          <div class="btnBox cursor">
            <img @click="lovePop = false" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="listContent" @scroll="handleScrollFollow" ref="chatContent">
          <user-list @directUser="directUser" @updateFollow="updateFollow" :list="followList" :type="3"></user-list>
        </div>
        <div class="popBottom"></div>
      </div>  
    </el-dialog>
    <!-- 系统消息 -->   
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="systemPop">
      <div class="directBox systemBox">
        <div class="popTit">
          <p>{{i18n.home.system}}</p>
          <div class="btnBox cursor">
            <img @click="closeSystem" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="listContent" @scroll="handleScrollSystem" ref="chatContent">
            <div class="list" v-for="(item,index) in systemMessage" :key="index">
                <div class="listLeft">
                  <p v-html="item.content"></p>
                  <!-- <p class="text">你给 <span class="color">用户名1</span> 赠送了 <span class="color">礼物名</span>（350金币），祝福语：祝你平安（350金币），祝福语：祝你平安（350金币），祝福语：祝你平安（350金币），祝福语：祝你平安</p> -->
                  <!-- <p class="time">{{item.create_time}}</p> -->
                </div> 
                <div v-if="item.is_read == 0" class="noPrompt bgcolor cursor" @click="promptBtn(item,index)">{{i18n.btn.prompt}}</div>
            </div>
        </div>
        <div class="popBottom"></div>
      </div> 
    </el-dialog>
    <!-- @我 -->
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="tipsPop">
      <div class="directBox recordsBox">
        <div class="popTit">
          <p>{{ i18n.home.me }}</p>
          <div class="btnBox cursor">
            <img @click="closeAte" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="listContent" @scroll="handleScrollAte">
          <about-list :type="3" :list="ateMessage"></about-list>
        </div>
        <div class="popBottom cursor" @click="meClear">{{i18n.btn.clearCon}}</div>
      </div>  
    </el-dialog>
    <!-- 点赞 -->
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="givelikePop">
      <div class="directBox giveLikeBox">
        <div class="popTit">
          <p>{{i18n.home.like}}</p>
          <div class="btnBox cursor">
            <img @click="givelikePop = false" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="selLike">
          <el-menu :default-active="likeIndex" class="el-menu-demo" mode="horizontal" @select="likeSelect">
            <el-menu-item index="2">{{i18n.pop.received}}</el-menu-item>
            <el-menu-item index="1">{{i18n.pop.liked}}</el-menu-item>
          </el-menu>
        </div>
        <div class="listContent" v-if="likeIndex == 2" @scroll="handleScrollFive" ref="chatContent">
          <about-list :type="2" :list="giveList"></about-list>
        </div>
        <div class="listContent" v-if="likeIndex == 1" @scroll="handleScrollFive" ref="chatContent">
          <about-list :type="1" :list="giveLists"></about-list>
        </div>
        <div class="popBottom cursor" @click="liveClear">{{i18n.btn.clearCon}}</div>
      </div>  
    </el-dialog>
    <!-- 粉丝 -->
    <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="vermicelliPop">
      <div class="directBox loveBox">
        <div class="popTit">
          <p>{{i18n.pop.fans}}</p>
          <div class="btnBox cursor" @scroll="handleScrollFans" ref="chatContent">
            <img @click="vermicelliPop = false" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
          </div>
        </div>
        <div class="listContent">
          <user-list @directUser="directUser" :list="fansList" :type="5"></user-list>
        </div>
        <div class="popBottom"></div>
      </div>  
    </el-dialog>
    <!-- 私聊聊天框 -->
    <message ref="openDirect" :messageType="messageType" :titName="titleName" :userId="messageUserid"></message> 
    <!-- 客服聊天框 -->
    <!-- <message ref="openDirectthree" :messageType="messageType" :titName="i18n.home.message + userName" :userId="messageUserid"></message>
    <message ref="openDirecttwo" :messageType="messageType" :titName="i18n.home.message + userName" :userId="messageUserid"></message> -->
    <!-- <message ref="openMeassge" :messageType="messageType" :titName="i18n.pop.service" :userId="serviceData"></message> -->
  </div>
</template>
  
<script>
import { Bus } from '../src/main';
import message from './message.vue';
import aboutList from './aboutList.vue';
import userList from './userList.vue'; 

export default {
  components:{message,aboutList,userList},
  data() {
    //这里存放数据
    return {
      titleName:'',
      messageType:1,
      userName:'', // 私聊当前用户
      countInfo:{},
      selList:0,
      userInfo:{},
      directPop: false, // 私聊弹出框
      recordsPop:false, // 来访记录 
      lovePop:false, // 我的关注
      systemPop:false, // 系统消息
      tipsPop:false, // @我
      givelikePop:false, // 点赞
      vermicelliPop:false, // 粉丝
      likeIndex:'2', // 点赞弹窗默认显示
      radio: 1, // 在线离线默认
      setList:[],
      sysNum:0, // 系统消息数
      messageNum:0, // 收到的私聊消息数
      messageList:[], // 私聊列表
      messageUserid:{}, // 选中的用户
      comeVisitList:[], // 来访记录列表
      recordsPage:{
        limit:15,
        page:1
      },
      recordsMore:true,
      followMore:true,
      followList:[], // 我的关注列表
      followPage:{
        limit:15,
        page:1
      },
      systemMore:true,
      systemMessage:[], // 系统消息列表
      systemPage:{
        limit:15,
        page:1
      },
      ateMore:true,
      ateMessage:[], // @我列表
      atePage:{
        limit:15,
        page:1
      },
      giveMore:true,
      giveList:[], // 点赞  1 我点的赞 2 收到的
      giveLists:[],
      givePage:{
        limit:15,
        page:1,
        type:2
      },
      userMore:true,
      userList:[],
      userPage:{
        page:1,
        limit:15,
        type:1
      },
      fansList:[],
      fansMore:true,
      fansFollow:{
        page:1,
        limit:15
      },
      scrollThrottleDelay:200,
      serviceData:{},
      service:[]
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {},
  methods: {
    // 获取用户信息
    getUserinfo(){
        this.$postRequest("UserAuth/getUserInfo").then((res) => {
            this.userInfo = res.data;
            localStorage.setItem('userInfo',JSON.stringify(res.data));
        });
    },
    // 获取用户统计数据
    getMessageCount(){
      this.$postRequest("/mini/getMessageCount").then((res) => {
          if(res.status == 200){
              this.countInfo = res.data;
          }
      });
    },
    // 弹出框 - 私聊 direct
    showPop(e,num){
      this.selList = num;
      if(e == 'directPop'){
        this.directPop = true;
        this.messageNum = 0;
        // this.getMessage();
        this.radio = 1;
        this.userPage.page = 1;
        this.userPage.type = 1;
        this.getUserlist(this.userPage)
      }else if(e == 'recordsPop'){
        // 来访记录
        this.recordsPop = true;
        this.getUserComeVisitList(this.recordsPage);
      }else if(e == 'lovePop'){
        // 我的关注
        this.lovePop = true;
        this.followPage.page = 1;
        this.userFollowList(this.followPage);
      }else if(e == 'systemPop'){
        // 系统消息
        this.systemPop = true;
        this.sysNum = 0;
        this.systemPage.page = 1;
        this.getSystemMessage(this.systemPage);
      }else if(e == 'tipsPop'){
        // @ 我
        this.tipsPop = true;
        this.atePage.page = 1;
        this.mentionMy(this.atePage);
      }else if(e == 'givelikePop'){
        // 点赞
        this.givelikePop = true;
        this.givePage.type = 2;
        this.givePage.page = 1;
        this.likeIndex = '2';
        this.userGiveList(this.givePage);
      }else if(e == 'vermicelliPop'){
        // 我的粉丝
        this.vermicelliPop = true;
        this.fansFollow.page = 1;
        this.fansFollowList(this.fansFollow);
      }else if(e == 'openService'){
        this.messageType = 4;
        this.$refs.openDirect.messageType = 4;
        this.titleName = this.i18n.pop.service;
        this.messageUserid = this.serviceData;
        this.$refs.openDirect.userId = this.serviceData;
        this.$refs.openDirect.messageOpen();
      }
    },
    // 获取客服
    getService(){
      this.$postRequest("Mini/getService").then((res) => {
          if(res.status == 200){
            this.serviceData = res.data[0];
            this.service = res.data;
          }
      });
    },
    // 私聊
    directUser(data){
      this.messageUserid = data.item;
      this.$refs.openDirect.userId = data.item;
      if(data.type == 5){
        this.userName = data.item.fans.nickname;
        this.titleName = this.i18n.home.message + this.userName;
        setTimeout(()=>{
          this.messageType = 2;
          this.$refs.openDirect.messageType = 2;
          // ===2
          this.$refs.openDirect.messageOpen();
        },200)
      }else if(data.type == 3){
        this.userName = data.item.user.nickname;
        this.titleName = this.i18n.home.message + this.userName;
        setTimeout(()=>{
          this.messageType = 3;
          this.$refs.openDirect.messageType = 3;
          // ==3
          this.$refs.openDirect.messageOpen();
        },200)
      }else if(data.type == 4){
        this.userName = data.item.getUser.nickname;
        this.titleName = this.i18n.home.message + this.userName;
        setTimeout(()=>{
          this.messageType = 2;
          this.$refs.openDirect.messageType = 2;
          // ===2
          this.$refs.openDirect.messageOpen();
        },200)
      }else{
        this.$refs.openDirect.messageType = 1;
        this.userName = data.item.friend_user.nickname;
        this.titleName = this.i18n.home.message + this.userName;
        setTimeout(()=>{
          this.$refs.openDirect.messageOpen();
        },200)
      }
    },
    sexChange(e){
      this.userPage.page = 1;
      this.userPage.type = e;
      this.getUserlist(this.userPage)
    },
    // 获取联系人列表
    getUserlist(data){
      // 1=在线，2=离线
      this.$postRequest("mini/getContacts",data).then((res) => {
          if(res.status == 200){
              if(this.userPage.page == 1){
                this.userList = res.data.data;
              }else{
                this.userList = [...this.userList,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.userPage.page++;
                this.userMore = true
              } else {
                this.userMore = false
              }
          }
      });
    },
    // 测试 ===获取消息列表
    getMessage(id){
        let data = {
            is_group:0,
            toContactId:id,
            limit:20,
            page:1
        }
        this.$postRequest("/enterprise/im/getContacts",data).then((res) => {
            if(res.code == 0){
                this.messageList = res.data;
            }
        });
    },
    // 获取来访记录
    getUserComeVisitList(data){
        this.$postRequest("userAuth/getUserComeVisitList",data).then((res) => {
            if(res.status == 200){
                this.comeVisitList = res.data.data;
                console.log(this.comeVisitList)
            }
            if(this.recordsPage.page == 1){
                this.comeVisitList = res.data.data;
              }else{
                this.comeVisitList = [...this.comeVisitList,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.recordsPage.page++;
                this.recordsMore = true
              } else {
                this.recordsMore = false
              }
        });
    },
    // 我的关注列表
    userFollowList(data){
        this.$postRequest("user/userFollowList",data).then((res) => {
            if(res.status == 200){
              if(this.followPage.page == 1){
                this.followList = res.data.data;
              }else{
                this.followList = [...this.followList,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.followPage.page++;
                this.followMore = true
              } else {
                this.followMore = false
              }
            }
        });
    },
    // 我的粉丝
    fansFollowList(data){
      this.$postRequest("user/fansFollowList",data).then((res) => {
            if(res.status == 200){
              if(this.fansFollow.page == 1){
                this.fansList = res.data.data;
              }else{
                this.fansList = [...this.fansList,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.fansFollow.page++;
                this.fansMore = true
              } else {
                this.fansMore = false
              }
            }
        });
    },
    // 更新我的关注 --取关成功后
    updateFollow(){
      this.userFollowList(this.followPage);
    },
    // 系统消息列表
    getSystemMessage(data){
        this.$postRequest("SystemMessage/getSystemMessage",data).then((res) => {
            if(res.status == 200){
                if(this.systemPage.page == 1){
                  this.systemMessage = res.data.data;
                }else{
                  this.systemMessage = [...this.systemMessage,...res.data.data];
                }
                if (res.data.last_page > res.data.current_page) {
                  this.systemPage.page++;
                  this.systemMore = true
                } else {
                  this.systemMore = false
                }
            }
        });
    },
    // @我列表
    mentionMy(data){
        this.$postRequest("user/mentionMy",data).then((res) => {
            if(res.status == 200){
                if(this.atePage.page == 1){
                  this.ateMessage = res.data.data;
                }else{
                  this.ateMessage = [...this.ateMessage,...res.data.data];
                }
                if (res.data.last_page > res.data.current_page) {
                  this.atePage.page++;
                  this.ateMore = true
                } else {
                  this.ateMore = false
                }
            }
        });
    },
    // 我点的赞
    userGiveList(data){
      this.$postRequest("user/userGiveList",data).then((res) => {
          if(res.status == 200){
            if(this.likeIndex == 2){
              this.giveList = res.data.data;
              if(this.givePage.page == 1){
                this.giveList = res.data.data;
              }else{
                this.giveList = [...this.giveList,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.givePage.page++;
                this.giveMore = true
              } else {
                this.giveMore = false
              }
            }else{
              this.giveLists = res.data.data;
              if(this.givePage.page == 1){
                this.giveLists = res.data.data;
              }else{
                this.giveLists = [...this.giveLists,...res.data.data];
              }
              if (res.data.last_page > res.data.current_page) {
                this.givePage.page++;
                this.giveMore = true
              } else {
                this.giveMore = false
              }
            }
              
          }
      });
    },
    // 点赞切换
    likeSelect(key, keyPath) {
      console.log(key, keyPath);
      this.likeIndex = key;
      this.givePage.type = parseInt(key);
      this.userGiveList(this.givePage);
    },
    // 不再提示
    promptBtn(item,index){
      this.$postRequest("SystemMessage/saveRead",{
        id:item.id
      }).then((res) => {
            if(res.status == 200){
                this.systemMessage[index].is_read= 1
            }
        });
    },
    // 来访记录 清空内容
    recordsClear(){
      this.$postRequest("userAuth/userClearUserComeVisit").then((res) => {
          if(res.status == 200){
            this.recordsPage.page = 1;
            this.getUserComeVisitList(this.recordsPage);
          }
      });
    },
    // @我 -- 清空内容
    meClear(){
      this.$postRequest("UserAuth/userAssociationDel").then((res) => {
        this.atePage.page = 1;
        this.mentionMy(this.atePage);
      });
    },
    // 点赞 -- 清空内容
    liveClear(){
      this.$postRequest("user/giveClear",{
          type:this.likeIndex
        }).then((res) => {
            this.givePage.page = 1;
            this.userGiveList(this.givePage);
        });
    },
    // 关注滑动
    handleScrollFollow(event) {
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (clientHeight + scrollTop >= scrollHeight - 5) {
              if(this.followMore){
                this.userFollowList(this.followPage);
              }
            }
            clearTimeout(this.scrollThrottleTimer);
            this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 点赞滑动
    handleScrollFive(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (clientHeight + scrollTop >= scrollHeight - 5) {
              if(this.giveMore){
                this.userGiveList(this.givePage);
              }
            }
            clearTimeout(this.scrollThrottleTimer);
            this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 系统消息滑动
    handleScrollSystem(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
          const { scrollTop, scrollHeight, clientHeight } = event.target;
          if (clientHeight + scrollTop >= scrollHeight - 5) {
            if(this.systemMore){
              this.getSystemMessage(this.systemPage);
            }
          }
          clearTimeout(this.scrollThrottleTimer);
          this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 私聊联系人滑动
    handleScrollUserlist(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
          const { scrollTop, scrollHeight, clientHeight } = event.target;
          if (clientHeight + scrollTop >= scrollHeight - 5) {
            if(this.userMore){
              this.getUserlist(this.userPage);
            }
          }
          clearTimeout(this.scrollThrottleTimer);
          this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 来访记录滑动
    handleScrollrecords(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
          const { scrollTop, scrollHeight, clientHeight } = event.target;
          if (clientHeight + scrollTop >= scrollHeight - 5) {
            if(this.recordsMore){
              this.getUserComeVisitList(this.recordsPage);
            }
          }
          clearTimeout(this.scrollThrottleTimer);
          this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 私聊联系人滑动
    handleScrollFans(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
          const { scrollTop, scrollHeight, clientHeight } = event.target;
          if (clientHeight + scrollTop >= scrollHeight - 5) {
            if(this.fansMore){
              this.fansFollowList(this.fansFollow);
            }
          }
          clearTimeout(this.scrollThrottleTimer);
          this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // @我
    handleScrollAte(event){
      if (!this.scrollThrottleTimer) {
        this.scrollThrottleTimer = setTimeout(() => {
          const { scrollTop, scrollHeight, clientHeight } = event.target;
          if (clientHeight + scrollTop >= scrollHeight - 5) {
            if(this.ateMore){
              this.mentionMy(this.atePage);
            }
          }
          clearTimeout(this.scrollThrottleTimer);
          this.scrollThrottleTimer = null;
        }, this.scrollThrottleDelay);
      }
    },
    // 页面滚动
    scrollToBottom() {
        this.$nextTick(() =>{
            if (this.$refs.chatContent) {
                this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
            }
        })
    },
    // 关闭弹框 更新列表消息
    openMessage(){
      this.directPop = false;
      this.getMessageCount();
    },
    // 关闭系统消息
    closeSystem(){
      this.systemPop = false;
      this.getMessageCount();
    },
    // 关闭At
    closeAte(){
      this.tipsPop = false;
      this.getMessageCount();
    },
    moveToFront(arr, prop, value,text,num) {
      // 查找对象
      const index = arr.findIndex(item => item[prop] === value);
      if (index !== -1) {
        // 删除对象
        const removedItem = arr.splice(index, 1)[0];
        // 将对象插入到最前面
        arr.splice(num, 0, removedItem);
        this.userList[num].number ++;
        if(this.userList[num].chat_message){
					this.userList[num].chat_message.content = text;
				}else{
          this.userPage.page = 1;
          this.getUserlist(this.userPage)
        }
      }else{
        this.userPage.page = 1;
        this.getUserlist(this.userPage)
      }
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  created() {
    this.getMessageCount();
    this.getUserinfo();
    this.getService();
    let that = this;
    // 订阅统计信息更新
    Bus.$on('updateMount',(data)=>{
      this.messageType = 1;
      this.messageUserid = {};
      // this.getUserlist(this.userPage);
      this.getMessageCount();
    })
    // 更新个人信息
    Bus.$on('updateInfo',()=>{
      this.getUserinfo();
    })
    // 订阅消息数量
    Bus.$on('updateNumber',()=>{
      this.countInfo.all_user_message_number ++;
    })
    // 刷新私聊列表 -- 消息数量
    Bus.$on('updateContacts',(data)=>{
      let text;
      if(data.data.type == 'text'){
        text = data.data.content;
      }else if(data.data.type == 'image'){
        text = `[${this.i18n.post.picture}]`;
      }else if(data.data.type == 'video'){
        text = `[${this.i18n.post.video}]`;
      }else if(data.data.type == 'music'){
        text = `[${this.i18n.post.yu}]`;
      }else{
        text = data.data.content;
      }
      that.moveToFront(that.userList, 'friend_id', parseInt(data.data.friend_id),text,0);

      // let text;
      // if(data.data.type == 'text'){
      //   text = data.data.content;
      // }else if(data.data.type == 'image'){
      //   text = `[${this.i18n.post.picture}]`;
      // }else if(data.data.type == 'video'){
      //   text = `[${this.i18n.post.video}]`;
      // }else if(data.data.type == 'music'){
      //   text = `[${this.i18n.post.yu}]`;
      // }else{
      //   text = data.data.content;
      // }
      // // 本人是客服时
      // if(this.userInfo.user_id == this.service[0].id){
      //   that.moveToFront(that.userList, 'friend_id', parseInt(data.data.friend_id),text,0);
			// }else if(data.data.sendid == this.service[0].id){
      //   // 接受的消息是客服时
      //   that.moveToFront(that.userList, 'friend_id', parseInt(data.data.friend_id),text,0);
      // }else{
      //   // 接受的消息是其他人
      //   that.moveToFront(that.userList, 'friend_id', parseInt(data.data.friend_id),text,this.service.length);
      // }
    })
    // 订阅客服消息数量
    Bus.$on('updategroupNumber',()=>{
      this.countInfo.all_customer_message_number ++;
    })
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    Bus.$on('updateInfo',()=>{
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    })
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less" scoped>
  img{
    max-width: 100%;
    height: auto;
  }
  .userBox{
    width: 304px;
    // height: 715px;
    border-radius: 5px;
    background-color: #fff;
    padding: 40px 15px 15px;
    box-sizing: border-box;
    .userHead{
      display: flex;
      align-items: center;
      margin-left: 15px;
      .headPic{
        width: 100px;
        height: 100px;
        position: relative;
        .lable{
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          padding: 0 5px;
          height: 28px;
          border-radius: 16px;
          background: #E9EAEB;
          box-shadow: 0px 0px 4px 0px rgba(215, 115, 241, 0.45);
          color: #9FA2A5;
          font-size: 15px;
          img{
            width: 29px;
            height: 29px;
          }
        }
      }
      .user{
        margin-left: 10px;
        color: #333333;
        font-size: 24px;
        .count{
          display: flex;
          margin-top: 12px;
        }
      }
      
    }
    .userCount{
      margin-top: 33px;
      width: 274px;
      // height: 102px;
      padding:15px 0;
      border-radius: 5px;
      background: #F8F2F5;
      display: flex;
      // align-items: center;
      .countList{
        font-size: 24px;
        color: #333333;
        flex: 1;
        text-align: center;
        p:nth-child(2){
          font-size: 14px;
          margin-top: 8px;
        }
      }
    }
    .userSet{
      margin-top: 15px;
      .setList{
        padding: 10px 0;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #333333;
        cursor: pointer; /* 小手样式 */
        img{
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
        .messageNum{
          color: #FFFFFF;
          font-size: 15px;
          margin-left: 15px;
          width: 23px;
          height: 23px;
          display: block;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .bottomBox{
      position: relative;
      margin-top: 25px;
    }
    .bottom{
      width: 250px;
      height: 182px;
      border-radius: 40px 40px 5px 5px;
      background: rgba(215, 115, 241, 0.3);
      margin: -9px auto 0;
    }
    .mountBox{
      width: 274px;
      height: 173px;
      padding: 20px 20px 15px;
      box-sizing: border-box;
      border-radius: 40px 40px 5px 5px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(255, 81, 174, 0.35);
      position: absolute;
      .mount{
        display: flex;
        // align-items: center;
        .countList{
          flex: 1;
          text-align: center;
          font-size: 24px;
          color: #333333;
          cursor: pointer; /* 小手样式 */
          p:nth-child(2){
            font-size: 14px;
            margin-top: 8px;
            color: #999999;
          }
        }
      }
      .postForm{
        width: 234px;
        height: 50px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 26px auto 0;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
  // .direct{
  //   cursor: pointer; /* 小手样式 */
  // }
  .directBox{
    width: 638px;
    border-radius: 5px;
    background: #FFFFFF;

    .popTit{ 
        width: 638px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #333333;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #FFD4EB 0%, #FFD4EB 100%);
        position: relative;
        .btnBox{
          position: absolute;
          right: 0;
          height: 62px;
          display: flex;
          align-items: center;
        }
    }
    .selNow{
      width: 100%;
      padding: 0 36px;
      box-sizing: border-box;
      height: 70px;
      display: flex;
      justify-content: end;
      align-items: center;
      ::v-deep .el-radio-group{
          .el-radio__inner{
              width: 20px;
              height: 20px;
              border-radius: 2px;
              border: none;
              background: #D8D8D8;
              &:after{
                  width: 5px;
                  height: 10px;
                  border-radius: 0;
                  border-right:1px solid #fff;
                  border-bottom:1px solid #fff;
                  background: none;
                  transform: rotate(45deg) translate(-125%,-25%);
              }
          }
          .is-checked{
              .el-radio__inner{
                  background: linear-gradient(180deg, #FF6FBC 0%, #D773F1 100%);
                  &:after{
                      width: 5px;
                      height: 10px;
                      border-radius: 0;
                      border-right:1px solid #fff;
                      border-bottom:1px solid #fff;
                      background: none;
                      transform: rotate(45deg) translate(-125%,-25%);
                  }
              }
          }
          .el-radio__label{
              font-size: 18px;
              color: #333333 !important;
          }
      }
    }
    .listContent{
      padding: 0 36px;
      height: 576px;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
    }
    .popBottom{
      width: 638px;
      height: 25px;
      border-radius: 0px 0px 5px 5px;
      background: #FFFFFF;
      box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .recordsBox{
    .listContent{
      padding: 20px 36px 0;
      height: 576px;
      overflow: hidden;
      overflow-y: scroll;
    }
    .popBottom{
      width: 638px;
      height: 73px;
      border-radius: 0px 0px 5px 5px;
      background: #FFFFFF;
      box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #E92E2E;
    }
  }
  .loveBox{
    .listContent{
      padding: 20px 36px 0;
      height: 626px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
  .systemBox{
    .listContent{
      padding: 20px 36px 0;
      height: 626px;
      overflow: hidden;
      overflow-y: scroll;
      .list{
        width: 566px;
        height: 80px;
        border-radius: 5px 10px 10px 10px;
        background: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 12px;
        box-sizing: border-box;
        margin-bottom: 15px;
        .listLeft{
          color: #333333;
          font-size: 14px;
          .time{
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
          }
        }
        .noPrompt{
          flex-shrink: 0;
          width: 86px;
          height: 36px;
          border-radius: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 14px;
        }
      }
    }
  }
  .giveLikeBox{
    .selLike{
      height: 70px;
      .el-menu{
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-menu-item{
          font-size: 18px;
          color: #999999;
          border-bottom: 6px solid transparent;
        }
        .is-active{
          font-size: 18px;
          background: linear-gradient(180deg, #FF6FBC 0%, #D773F1 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          &::after{
            content: '';
            width: 72px;
            height: 6px;
            border-radius: 18px;
            background: linear-gradient(96deg, #FE64BA 11%, #D773F1 85%);
            display: block;
          }
        }
      }
    }
    .listContent{
      padding: 20px 36px 0;
      height: 506px;
      overflow: hidden;
      overflow-y: scroll;
    }
    
    .popBottom{
      width: 638px;
      height: 73px;
      border-radius: 0px 0px 5px 5px;
      background: #FFFFFF;
      box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #E92E2E;
    }
  }
  .messageBox{
    width: 700px;
    .popTit{
      width: 700px;
    }
    .popBottom{
      width: 100%;
    }
    .listContent{
      width: 700px;
      box-sizing: border-box;
    }
  }
</style>