<template>
  <div id="app">
    <keep-alive :exclude="['product_detail','journalism_detail','college_detail']">
        <router-view :width="width"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { Bus } from './main';
export default {
  name: 'App',
  data() {
    //这里存放数据
    return {
      show: false,
      width: '',
    }
  },
  mounted(){
    if(JSON.parse(localStorage.getItem('userInfo'))){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.websocket.Init(userInfo.user_id)
      // setTimeout(()=>{
      //   let online = {
      //     type: 'online',
      //     user_id:userInfo.user_id,
      //     time: (new Date()).getTime(),
      //   }
      //   console.log(online)
      //   this.websocket.Send(online);
      // },200)
    }
    this.$postRequest("login/getLanguage").then((res) => {
        let data = res.data;
        const entriesArray = Object.keys(data).map(key=>({key,value:data[key]}));
        localStorage.setItem('language',JSON.stringify(entriesArray));
        if(!localStorage.getItem('selLanguage')){
          localStorage.setItem('selLanguage',entriesArray[0].key);
        }
    });
  },
  created() {
    // 异地登录--跳转到登录页
    Bus.$on('goOut',(res)=>{
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      this.$method.skip('/login');
    })
    // 接收消息 并进行分发
    Bus.$on('appendMessage',(res)=>{
      let that = this;
      console.log('监听到消息',res,res.data);
				let data = res.data;
				let userInfo = localStorage.getItem('userInfo');
				// 确定接受人是谁
				let toUser = data.from_user;
				if (data.from_user == userInfo.user_id) {
					toUser = data.toUser
				}
				// let contact = that.getContact(toUser);
				if (data.from_user == 'system') {
					// 系统消息只需要把未读数增加一
				} else {
					// 判断是否是自己,自己发的看接收人是谁，去更新接受人的信息
					let addUnread = 1;
					// 自己发送的不需要加
					if (data.from_user == userInfo.user_id) {
						addUnread = 0;
					}
					let at = 0;
					// // 如果at参数包含了我自己，就要增加@的数量
					// if (data.at.includes(userInfo.user_id)) {
					// 	at = 1;
					// }
          console.log('1',res.data)
					// Bus.$emit('updateContacts', res,res.data)
					Bus.$emit('updateContacts',res)

          if(res.data.is_group == 2){
            Bus.$emit('updategroupNumber', false)
            Bus.$emit('updateNumber', false)
          }else{
            Bus.$emit('updateNumber', false)
          }
					// if (contact) {
					// 	contact.lastContent = data.content;
					// 	contact.lastSendTime = data.sendTime * 1000;
					// 	contact.type = data.type;
					// 	contact.unread += addUnread;
					// 	contact.is_at += at;
					// 	// 更新联系人信息
					// 	Bus.$emit('updateContacts', contact)
					// } else {
					// 	let newContact = {
					// 		id: data.from_user,
					// 		displayName: data.fromUser.displayName,
					// 		avatar: data.fromUser.avatar,
					// 		lastContent: data.content,
					// 		lastSendTime: data.sendTime * 1000,
					// 		is_group: data.is_group,
					// 		unread: addUnread,
					// 		is_top: 0,
					// 		dep_id: 0,
					// 		is_at: at
					// 	}
          //   console.log(newContact,'newContact','1111111111');
					// 	// uni.$emit('appendContacts', newContact)
					// }
					// // #ifdef APP-PLUS
					// let appStatus = this.appStatus;
					// // 如果app在后台运行,不是自己发的，并且开启了通知，就创建通知栏
					// if (!appStatus && data.fromUser.id != userInfo.user_id && contact.is_notice == 1) {
					// 	// this.createPushMsg(data,contact);
					// }
					// // #endif
				}

			})
  },
  methods: {
    getContact(id) {
      let contacts = JSON.parse(localStorage.getItem('allContacts'));
      if (!id) {
        return contacts;
      } else {
        const contact = contacts.find(obj => obj.id == id);
        return contact;
      }
    }
  }
}
</script>

<style>
  #app{
    background-color: #fff;
  }
  *{
    padding: 0;
    bottom: 0;
  }
  img {
    max-width: 100% !important;
    height: auto;
  }
  .el-message {
    height: 50px; /* 使用 !important 确保优先级 */
  }
  /* 去掉文本框的右边拉伸 */
  .el-textarea__inner{
      resize:none !important;
  }
  .el-dialog{
    background:transparent !important;
    box-shadow: none !important;
    border: none;
  }
  /* 弹出框 */
  .el-dialog__wrapper{
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dialog__header{
      height: 0;
      padding: 0;
    }
    .el-dialog__body{
      padding: 0;
    }
    .el-dialog{
      border-radius: 10px;
      overflow: hidden;
      margin-top: 0 !important;
    }
  }
  
</style>
